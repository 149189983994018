import React from 'react';
import cl from 'classnames';
import { withNamespaces } from 'react-i18next';

import Application from './Application';
import ListOfFeatures from './ListOfFeatures';
import Review from './Review';
import Link from '../Link';
import Api from '../../utils/api';
import SiteTitle from '../PageHeaderTitle';
import { removeTags } from '../../utils/strings';

import s from './Application.module.scss';

import img from '../../assets/img/applications/router.png';
import img2 from '../../assets/img/applications/router@2x.png';
import img3 from '../../assets/img/applications/router@3x.png';

import card3Img from '../../assets/img/applications/noun-refund-1355939.svg';
import card4Img from '../../assets/img/applications/noun-swedish-912926.svg';
import card8Img from '../../assets/img/applications/dns.svg';
import card6Img from '../../assets/img/applications/group-10.svg';
import card9Img from '../../assets/img/applications/connect.svg';
import card5Img from '../../assets/img/applications/global.svg';

export const ApplicationRouter = withNamespaces()(({ t, gatsbyUrl, pythonUrl, i18n, brand }) => {
  const api = new Api({ gatsbyUrl, pythonUrl, i18n });

  return (
    <Application
      h1={t('page_applications:h1_router')}
      text={t('page_applications:text_1_router')}
      list={t('page_applications:list_router')}
      img={{ img, img2, img3 }}
      img_alt={t('page_applications:image_alt_router')}
      img_title={`${brand.name} ${t('page_applications:image_alt_router')}`}
    >
      <SiteTitle brandName={brand.name} title={removeTags(t('page_applications:h1_router'))} />
      <div className={s.buttons}>
        <Link className={cl(s.button, s.button_dark)} to={api.applicationRouterGuideUrl()}>
          {t('page_applications:setup_guides')}
        </Link>
      </div>
    </Application>
  );
});

export const ListOfFeaturesRouter = withNamespaces()(({ t }) => (
  <ListOfFeatures cards={[
    { title: t('page_applications:card_4_title'), text: t('page_applications:card_4_text'), imgSrc: card4Img },
    { title: t('page_applications:card_8_title'), text: t('page_applications:card_8_text'), imgSrc: card8Img },
    { title: t('page_applications:card_9_title'), text: t('page_applications:card_9_text'), imgSrc: card9Img },
    { title: t('page_applications:card_5_title'), text: t('page_applications:card_5_text'), imgSrc: card5Img },
    { title: t('page_applications:card_3_title'), text: t('page_applications:card_3_text'), imgSrc: card3Img },
    { title: t('page_applications:card_6_title'), text: t('page_applications:card_6_text'), imgSrc: card6Img },
  ]}
  />
));

export const ReviewRouter = withNamespaces()(({ t }) => (
  <Review
    text={t('page_applications:review_1')}
    author={t('page_applications:anonymous')}
  />
));
